import { defineMessages } from "react-intl";

export const actions = defineMessages({
  clone: {
    id: "component.campaignActions.cloneCampaign",
    defaultMessage: "Duplica campagna",
  },
  copyLink: {
    id: "component.campaignActions.copyLink",
    defaultMessage: "Copia link di condivisione",
  },
  delete: {
    id: "component.campaignActions.deleteCampaign",
    defaultMessage: "Elimina la campagna",
  },
  edit: {
    id: "component.campaignActions.editCampaign",
    defaultMessage: "Modifica campagna",
  },
  info: {
    id: "component.campaignActions.moreInfo",
    defaultMessage: "Maggiori informazioni",
  },
  join: {
    id: "component.campaignActions.joinCampaign",
    defaultMessage: "Aderisci alla campagna",
  },
  joinWaitingList: {
    id: "common.actions.joinWaitingList",
    defaultMessage: "Lista d'attesa",
  },
  loginOrRegister: {
    id: "common.actions.loginOrRegister",
    defaultMessage: "Accedi o registrati",
  },
  showCode: {
    id: "common.actions.showCode",
    defaultMessage: "Visualizza il codice",
  },
  start: {
    id: "common.actions.start",
    defaultMessage: "Avvia evento",
  },
  stats: {
    id: "common.actions.stats",
    defaultMessage: "Statistiche",
  },
  stop: {
    id: "common.actions.stop",
    defaultMessage: "Ferma evento",
  },
  verifyAccount: {
    id: "common.actions.verifyAccount",
    defaultMessage: "Verifica il tuo account",
  },
  waiting: {
    id: "component.campaignActions.waitingList",
    defaultMessage: "Lista d'attesa",
  },
});
