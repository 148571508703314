import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, Prompt } from "react-router-dom";
import { endOfDay } from "date-fns";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import { useBmapi } from "../../utils/bmapi-context";
import { getLabel, useCampaigns } from "../../utils/campaigns";
import {
  MANAGER_ROUTES,
  PRODUCT_TYPES,
  PRODUCT_SUBTYPES,
} from "../../utils/constants";
import { formatValues, getInitialState } from "../../utils/campaignCreation";
import { getErrorMessageString } from "../../utils/errors";
import {
  common,
  confirm,
  form,
  navigation,
  notifications,
  product,
  types,
} from "../../messages";

import AutoJoin from "./input/AutoJoin";
import Cover from "./input/Cover";
import CodesPrefix from "./input/CodesPrefix";
import Currency from "./input/Currency";
import Demo from "./input/Demo";
import Description from "./input/Description";
import DynamicValue from "./input/DynamicValue";
import EarningMinimumThreshold from "./input/EarningMinimumThreshold";
import ExpirationDate from "./input/ExpirationDate";
import EndHour from "./input/EndHour";
import ExternalIdFormat from "./input/ExternalIdFormat";
import FormSection from "./input/FormSection";
import GracePeriod from "./input/GracePeriod";
import Icon from "./input/Icon";
import LinkDistribution from "./input/LinkDistribution";
import MainEventId from "./input/MainEventId";
import MaxIssueNumber from "./input/MaxIssueNumber";
import MaxProductsPerUser from "./input/MaxProductsPerUser";
import Name from "./input/Name";
import Prizes from "./input/Prizes";
import FrontEndType from "./input/FrontEndType";
import ProductLifespan from "./input/ProductLifespan";
import ProductLimitDate from "./input/ProductLimitDate";
import ProductName from "./input/ProductName";
import Quantum from "./input/Quantum";
import Rate from "./input/Rate";
import ShotNumber from "./input/ShotNumber";
import StartDate from "./input/StartDate";
import StartHour from "./input/StartHour";
import TosConsumerUrl from "./input/TosConsumerUrl";
import TosMerchantUrl from "./input/TosMerchantUrl";
import UseFrequency from "./input/UseFrequency";
import UsePeriod from "./input/UsePeriod";
import Value from "./input/Value";
import WaitingList from "./input/WaitingList";
import ManagerRestriction from "./input/ManagerRestriction";

function Navigation() {
  const intl = useIntl();

  return (
    <FormControl margin="normal" fullWidth>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", "")}
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage(navigation.backToCampaigns)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.HOME}
            startIcon={<ArrowForward />}
          >
            {intl.formatMessage(navigation.goHome)}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default function CommonCreateForm({
  handleSubmit,
  initialValues,
  onTypeChange,
  ...props
}) {
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const { campaigns, loadCampaigns } = useCampaigns();
  const [dirty, setDirty] = useState(false);
  const [values, setValues] = useState(initialValues);

  const checkPrizes = useCallback(() => {
    return !values.prizes
      .map(
        (p) =>
          campaigns.find((c) => c.campaign_id === p.prize_id).expiration_date
      )
      .some(
        (exp) => endOfDay(new Date(exp)) < endOfDay(values.product_limit_date)
      );
  }, [values, campaigns]);

  const saveImages = (values) => async (campaignView) => {
    if (typeof values.cover === "object") {
      await bmapi
        .uploadCampaignCover(campaignView.id, values.cover)
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    }
    if (typeof values.icon === "object") {
      await bmapi
        .uploadCampaignIcon(campaignView.id, values.icon)
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    }
    setDirty(false);
    return campaignView;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!checkPrizes()) {
      return notifyError(
        intl.formatMessage(notifications.wrongPrizeExpiration)
      );
    }

    handleSubmit(formatValues(values), saveImages(values)).catch((e) =>
      notifyError(getErrorMessageString(e, intl))
    );
  };

  const handleChange = useCallback(
    (label) => {
      const updateValue = (val) => {
        setDirty(true);
        setValues((v) => ({ ...v, [label]: val }));
      };

      const updateProduct = (e) => {
        setDirty(false);
        setValues((vs) => getInitialState(e.target.value, bmapi, vs));
      };

      const parseInput = (i, f) => {
        if (typeof f === "boolean") updateValue(f);
        else if (i?.target) updateValue(i.target.value);
        else updateValue(i);
      };

      return label === "front_end_type" ? updateProduct : parseInput;
    },
    [bmapi]
  );

  useEffect(() => {
    if (
      !campaigns &&
      [
        PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
        PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      ].includes(values.subtype)
    ) {
      loadCampaigns();
    }
  }, [values.subtype, campaigns, loadCampaigns]);

  useEffect(() => {
    onTypeChange && onTypeChange(values.front_end_type);
  }, [onTypeChange, values.front_end_type]);

  const inputProps = {
    campaign: props.campaign,
    campaigns,
    handleChange,
    values,
    productType: getLabel(values.subtype),
  };

  return (
    <React.Fragment>
      <Prompt when={dirty} message={intl.formatMessage(confirm.exitPrompt)} />

      <Card>
        <CardContent>
          <form onSubmit={onSubmit}>
            <FrontEndType {...inputProps} />

            {!!values.subtype && (
              <React.Fragment>
                <Typography variant="caption">
                  {intl.formatMessage(types[values.front_end_type].description)}
                </Typography>

                <FormSection>
                  <MainEventId {...inputProps} />
                  <Name {...inputProps} />
                  <ProductName {...inputProps} />
                  <Description {...inputProps} />
                  <CodesPrefix {...inputProps} />
                  <StartDate {...inputProps} />
                  <StartHour {...inputProps} />
                  <ExpirationDate {...inputProps} />
                  <EndHour {...inputProps} />
                  <TosConsumerUrl {...inputProps} />
                  <TosMerchantUrl {...inputProps} />
                  <LinkDistribution {...inputProps} />
                  <Demo {...inputProps} />
                  <AutoJoin {...inputProps} />
                  <WaitingList {...inputProps} />
                  <ManagerRestriction {...inputProps} />
                </FormSection>

                <FormSection title={intl.formatMessage(form.images)}>
                  <Cover {...inputProps} />
                  <Icon {...inputProps} />
                </FormSection>

                <FormSection
                  title={intl.formatMessage(
                    product[inputProps.productType].info
                  )}
                >
                  <ProductLimitDate {...inputProps} />
                  <ProductLifespan {...inputProps} />
                  <GracePeriod {...inputProps} />
                  <MaxIssueNumber {...inputProps} />
                  <MaxProductsPerUser {...inputProps} />
                </FormSection>

                <FormSection title={intl.formatMessage(form.details)}>
                  <DynamicValue {...inputProps} />
                  <Value {...inputProps} />
                  <Currency {...inputProps} />
                  <ShotNumber {...inputProps} />
                  <ExternalIdFormat {...inputProps} />
                  <Rate {...inputProps} />
                  <Quantum {...inputProps} />
                </FormSection>

                {values.type === PRODUCT_TYPES.CAMPAIGN_COUPON && (
                  <FormSection title={intl.formatMessage(form.constraints)}>
                    <UseFrequency {...inputProps} />
                    <UsePeriod {...inputProps} />
                  </FormSection>
                )}

                {values.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD && (
                  <FormSection title={intl.formatMessage(form.constraints)}>
                    <EarningMinimumThreshold {...inputProps} />
                  </FormSection>
                )}

                {values.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD && (
                  <FormSection title={intl.formatMessage(common.prizes)}>
                    <Prizes {...inputProps} />
                  </FormSection>
                )}

                <FormControl fullWidth margin="normal">
                  <Button variant="contained" color="primary" type="submit">
                    {intl.formatMessage(
                      props.campaign ? common.save : common.create
                    )}
                  </Button>
                </FormControl>
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>
      <Navigation />
    </React.Fragment>
  );
}
