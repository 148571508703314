import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { Container, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import CommonCreateForm from "../../ui/forms/CommonCreateForm";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getInitialState } from "../../utils/campaignCreation";
import {
  MANAGER_ROUTES,
  PRODUCTS,
  PRODUCT_SUBTYPES,
} from "../../utils/constants";

export default function CampaignClone() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifySuccess, startLoading, stopLoading } = useBmapi();
  const [initialValues, setInitialValues] = useState(false);

  useEffect(() => {
    function campaignFrontendType(campaign) {
      if (campaign.front_end_type) return campaign.front_end_type;

      switch (campaign.rules.subtype) {
        case PRODUCT_SUBTYPES.COUPON_SIMPLE:
          return campaign.rules.shot_number === 1
            ? PRODUCTS.COUPON_SIMPLE
            : PRODUCTS.COUPON_MULTISHOT;
        case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
          return PRODUCTS.COUPON_DISCOUNT_PERC;
        case PRODUCT_SUBTYPES.COUPON_VALUE:
          return PRODUCTS.COUPON_DISCOUNT_VALUE;
        case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
          return PRODUCTS.EARNING_CARD;
        case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
          return PRODUCTS.SHOPPING_CARD;
        case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
          return PRODUCTS.EVENT_PASS;
        case PRODUCT_SUBTYPES.PROVISIONING_CARD:
          return PRODUCTS.PROVISIONING_CARD;
      }
    }

    startLoading();
    bmapi.getCampaignDetails(campaignId).then(({ campaign }) => {
      setInitialValues(
        getInitialState(
          campaignFrontendType(campaign),
          bmapi,
          false,
          campaign,
          true
        )
      );
      stopLoading();
    });
  }, [bmapi, campaignId, startLoading, stopLoading]);

  const handleSubmit = (values, after) => {
    startLoading();

    return bmapi
      .saveCampaign(values)
      .then(after)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.campaignClone.saved",
            defaultMessage: "Campagna duplicata con successo",
          })
        );
        history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
      })
      .catch(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>
        <IconButton
          component={RouterLink}
          to={MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", "")}
          size="small"
        >
          <ArrowBack />
        </IconButton>{" "}
        <FormattedMessage
          id="pages.campaignClone.title"
          defaultMessage="Duplica campagna per {businessName}"
          values={{
            businessName: <strong>{bmapi.getUserInfo().business.name}</strong>,
          }}
        />
      </Title>

      {!!initialValues && (
        <CommonCreateForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />
      )}
    </Container>
  );
}
