import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, Prompt, useHistory } from "react-router-dom";
import { format } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Link,
  List,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Store } from "@material-ui/icons";

import { account, accountExtra, common, confirm } from "../messages";
import Confirm from "../ui/Confirm";
import FormSection from "../ui/forms/input/FormSection";
import {
  Address,
  Birthday,
  Cap,
  City,
  DirectMarketing,
  FiscalCode,
  Gender,
  IndirectMarketing,
  Language,
  LastName,
  Mobile,
  Name,
  Password,
  ProfilePicture,
  SecondaryEmail,
} from "../ui/forms/profile";
import ListItemInfo from "../ui/ListItemInfo";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, FEATURES, MANAGER_ROUTES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import { Alert } from "@material-ui/lab";

function getFlags(obj, flag) {
  return Object.keys(obj).reduce((a, c) => ({ ...a, [c]: flag }), {});
}

const extraIcons = {
  registration_business: Store,
};

export const extraProfileFields = [
  { key: "name", Component: Name },
  { key: "last_name", Component: LastName },
  { key: "birthday", Component: Birthday },
  { key: "gender", Component: Gender },
  { key: "mobile", Component: Mobile },
  { key: "secondary_email", Component: SecondaryEmail },
  { key: "fiscal_code", Component: FiscalCode },
  { key: "addess", Component: Address },
  { key: "cap", Component: Cap },
  { key: "city", Component: City },
  { key: "language", Component: Language },
];

const createLink = (str, content, ROUTES) => (
  <Link
    key={str}
    component={RouterLink}
    to={ROUTES.STATIC.replace(":content?/", content)}
    target="_blank"
  >
    {str}
  </Link>
);

const externalLink = (str, link) => (
  <Link key={str} href={link} target="_blank">
    {str}
  </Link>
);

const Bold = (str) => <strong key={str}>{str}</strong>;
const Underline = (str) => <u key={str}>{str}</u>;

export function getFormatter(bmapi) {
  const ROUTES = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;
  const createLinkTos = (str) => createLink(str, "tos", ROUTES);
  const createLinkPrivacy = (str) =>
    bmapi.businessProfile.privacyLink
      ? externalLink(str, bmapi.businessProfile.privacyLink)
      : createLink(str, "privacy", ROUTES);

  return {
    br: "\n",
    minAge: bmapi.settings.minAge,
    privacyLink: createLinkPrivacy,
    programName: bmapi.businessProfile.programName,
    strong: Bold,
    tosLink: createLinkTos,
    u: Underline,
  };
}

function getInitialValues(userData) {
  return userData
    ? {
        address: userData.address || "",
        avatar: null,
        birthday: userData.birthday ? new Date(userData.birthday) : null,
        cap: userData.cap || "",
        city: userData.city || "",
        direct_marketing: userData.direct_marketing,
        email: userData.email || "",
        fiscal_code: userData.fiscal_code || "",
        gender: userData.gender || "",
        indirect_marketing: userData.indirect_marketing,
        language: userData.language || "",
        last_name: userData.last_name || "",
        mobile: userData.mobile || "",
        name: userData.complete_name || "",
        newPassword: "",
        password: "",
        privacy: true,
        rules: true,
        secondary_email: userData.secondary_email,
      }
    : false;
}

export default function Account() {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const history = useHistory();
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState(false);

  const extraInfo = (bmapi.userData?.front_end_additional_keys || []).map(
    (k, i) => ({
      label: k,
      value: bmapi.userData?.front_end_additional_values[i],
    })
  );

  const initialDirty = getFlags(initialValues, false);
  const initialValid = getFlags(initialValues, true);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [valid, setValid] = useState(initialValid);
  const [dirty, setDirty] = useState(initialDirty);
  const [filePreview, setFilePreview] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const ROUTES = bmapi.isManager() ? MANAGER_ROUTES : CONSUMER_ROUTES;

  const handleCapture = (event) => {
    const fileSelected = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const img = new Image();

      img.onload = function () {
        const AVATAR_SIZE = 200;
        const canvas = document.createElement("canvas");
        canvas.width = AVATAR_SIZE;
        canvas.height = AVATAR_SIZE;

        const width =
          img.width < img.height
            ? AVATAR_SIZE
            : (img.width * AVATAR_SIZE) / img.height;
        const height =
          img.width > img.height
            ? AVATAR_SIZE
            : (img.height * AVATAR_SIZE) / img.width;
        const offsetY = img.width < img.height ? (height - width) / -2 : 0;
        const offsetX = img.width > img.height ? (width - height) / -2 : 0;

        canvas.getContext("2d").drawImage(img, offsetX, offsetY, width, height);
        canvas.toBlob(
          (avatar) => setValues({ ...values, avatar }),
          "image/jpeg",
          0.65
        );

        setDirty({ ...dirty, avatar: !!fileSelected });
        setValid({ ...valid, avatar: !!fileSelected });
        setFilePreview(canvas.toDataURL("image/jpeg", 0.65));
      };
      img.src = e.target.result;
    };
    fileReader.readAsDataURL(fileSelected);
  };

  function handleChange(valueLabel) {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [valueLabel]: val }));

      if (valueLabel !== "password") {
        setDirty({
          ...dirty,
          [valueLabel]: val !== initialValues[valueLabel],
        });
      }
      if (valueLabel === "password" || valueLabel === "newPassword") {
        setValid({
          ...valid,
          [valueLabel]: bmapi.validatePassword(val),
        });
      }
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (typeof f === "string") updateValue(JSON.parse(f));
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formattedValues = {
      ...values,
      birthday: values.birthday ? format(values.birthday, "yyyy-MM-dd") : "",
      metadata: {
        address: values.address,
        cap: values.cap,
        city: values.city,
        profile_version: bmapi.settings.profileVersion,
        registration_code: bmapi.userData.registration_code,
        secondary_email: values.secondary_email,
      },
    };

    bmapi
      .saveUser(formattedValues)
      .then(() => dirty.avatar && bmapi.uploadProfilePicture(values.avatar))
      .then(
        () =>
          dirty.newPassword &&
          bmapi.doPasswordUpdate(values.password, values.newPassword)
      )
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
        setInitialValues(values);
        setDirty(initialDirty);
        setValid(initialValid);
      })
      .catch((e) => {
        console.error(e);
        notifyError(getErrorMessageString(e, intl));
      });
  }

  function deleteAccount() {
    bmapi
      .requestAccountDelete()
      .then(() => {
        notifySuccess(intl.formatMessage(account.deleteConfirmMessage));
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setDeleteRequest(false));
  }

  function requestDelete() {
    setDeleteRequest(true);
  }

  useEffect(() => {
    const status =
      Object.values(dirty).some((v) => v) &&
      Object.values(valid).every((v) => v);

    window.onbeforeunload = status ? () => true : undefined;
    setEnabled(status);

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [valid, dirty]);

  useEffect(() => {
    startLoading();
    bmapi.refreshUserData().then(() => {
      setInitialValues(getInitialValues(bmapi.userData));
    });
  }, [bmapi, startLoading]);

  useEffect(() => {
    if (initialValues) stopLoading();
    setValues(initialValues);
  }, [initialValues, stopLoading]);

  const requestDeleteLink = (str) => (
    <Link onClick={requestDelete} key={str}>
      {str}
    </Link>
  );

  const formatValues = useMemo(() => getFormatter(bmapi), [bmapi]);

  const activeFields = extraProfileFields.filter((i) =>
    bmapi.settings.profile.includes(i.key)
  );

  return (
    !!values && (
      <Container maxWidth="sm">
        <Confirm
          open={!!deleteRequest}
          onConfirm={deleteAccount}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(account.deleteConfirm)}
          flag
        />
        <Title backUrl={ROUTES.HOME}>
          {intl.formatMessage(account.pageTitle)}
        </Title>
        <Prompt
          when={enabled}
          message={intl.formatMessage(confirm.exitPrompt)}
        />
        {bmapi.can(FEATURES.PROFILE_COMPLETE) &&
          !bmapi.userData?.profile_complete && (
            <Box mb={3}>
              <Alert severity="info">
                Completa il profilo per avere dei punti bonus
              </Alert>
            </Box>
          )}
        <Box mb={3}>
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <FormSection title={intl.formatMessage(common.email)}>
                  <Typography>{values.email}</Typography>
                </FormSection>
                {bmapi.settings.profile.includes("avatar") && (
                  <FormSection title="Avatar">
                    <ProfilePicture
                      filePreview={filePreview}
                      handleCapture={handleCapture}
                    />
                  </FormSection>
                )}
                <FormSection title={intl.formatMessage(account.changePassword)}>
                  <Password
                    dirty={dirty.password}
                    onChange={handleChange("password")}
                    show={showPassword}
                    toggleShow={handleClickShowPassword}
                    valid={valid.password}
                    value={values.password}
                  />
                  <Password
                    confirm
                    dirty={dirty.newPassword}
                    onChange={handleChange("newPassword")}
                    show={showNewPassword}
                    toggleShow={handleClickShowNewPassword}
                    valid={valid.newPassword}
                    value={values.newPassword}
                  />
                </FormSection>
                <FormSection title={intl.formatMessage(account.personalData)}>
                  {activeFields.map((field) => (
                    <field.Component
                      key={field.key}
                      value={values[field.key]}
                      onChange={handleChange(field.key)}
                    />
                  ))}
                </FormSection>
                {bmapi.isConsumer() && (
                  <FormSection title={intl.formatMessage(account.marketing)}>
                    {bmapi.settings.profile.includes("direct_marketing") && (
                      <DirectMarketing
                        value={values.direct_marketing}
                        onChange={handleChange("direct_marketing")}
                        formatValues={formatValues}
                      />
                    )}
                    {bmapi.settings.profile.includes("indirect_marketing") && (
                      <IndirectMarketing
                        value={values.indirect_marketing}
                        onChange={handleChange("indirect_marketing")}
                        formatValues={formatValues}
                      />
                    )}
                  </FormSection>
                )}
                <FormControl margin="normal" fullWidth>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {intl.formatMessage(common.save)}
                  </Button>
                </FormControl>
              </CardContent>
            </Card>
          </form>
        </Box>

        {bmapi.can(FEATURES.ACCOUNT_EXTRA_INFO) && extraInfo.length > 0 && (
          <Box mb={3}>
            <Card>
              <CardContent>
                <Typography component="legend" variant="h6" gutterBottom>
                  {intl.formatMessage(account.extraInfo)}
                </Typography>
                <List>
                  {extraInfo.map((info) => (
                    <ListItemInfo
                      key={info.label}
                      Icon={extraIcons[info.label] || false}
                      label={intl.formatMessage(accountExtra[info.label])}
                      text={info.value}
                      disableGutters
                    />
                  ))}
                </List>
              </CardContent>
            </Card>
          </Box>
        )}

        {bmapi.can(FEATURES.DELETE_ACCOUNT) && (
          <Card>
            <CardContent>
              <Typography component="legend" variant="h6" gutterBottom>
                {intl.formatMessage(account.removeAccount)}
              </Typography>

              <Typography>
                {intl.formatMessage(account.removeAccountText, {
                  link: requestDeleteLink,
                })}
              </Typography>
            </CardContent>
          </Card>
        )}
        <FormControl margin="normal">
          <Button
            onClick={() => history.push(ROUTES.HOME)}
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage(common.backHome)}
          </Button>
        </FormControl>
      </Container>
    )
  );
}
