import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QRCodeGenerator from "qrcode";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Apps, Delete, Edit, MoreVert } from "@material-ui/icons";

import { common, form } from "../messages";
import FloatingActions, { Action } from "../ui/FloatingActions";
import { useBmapi } from "../utils/bmapi-context";
import { LOCATION_PREFIX } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import styles from "../utils/styles";

function LocationElement({ location, onModify, onDelete, onShow }) {
  const classes = styles.useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleDelete = () => {
    setLoading(true);
    setAnchorEl(null);
    onDelete(location.id);
  };

  const handleModify = () => {
    setAnchorEl(null);
    onModify(location);
  };

  const showCode = () => {
    setAnchorEl(null);
    onShow(location);
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemText
          primary={location.name}
          primaryTypographyProps={{ variant: "h6" }}
          secondary={
            location.description || (
              <i>
                <FormattedMessage
                  id="component.manageLocations.noDescription"
                  defaultMessage="Nessuna descrizione"
                />
              </i>
            )
          }
        />
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{location.name}</Typography>
              </MenuItem>
              <MenuItem onClick={showCode}>
                <ListItemIcon>
                  <Apps fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.showCode"
                    defaultMessage="Mostra QR Code"
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.editLocation"
                    defaultMessage="Modifica località"
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.deleteLocation"
                    defaultMessage="Elimina località"
                  />
                </Typography>
              </MenuItem>
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
}

const initialValues = (bs = {}) => ({
  description: bs.description || "",
  name: bs.name || "",
});

export default function ManageLocations() {
  const intl = useIntl();
  const { bmapi, businessId, notifySuccess, notifyError } = useBmapi();
  const [openDialog, setOpenDialog] = useState(false);
  const [code, setCode] = useState(false);
  const [codeLocation, setCodeLocation] = useState(false);
  const [locations, setLocations] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [values, setValues] = useState(initialValues());

  const handleValue = useCallback(
    (label) => (e) => {
      ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.value);
    },
    []
  );

  const handleDelete = (id) => {
    bmapi
      .deleteLocation(id)
      .then(() => bmapi.getLocations().then(setLocations))
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "component.manageLocations.locationRemoved",
            defaultMessage: "Località eliminata con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const handleModify = (location) => {
    setValues(initialValues(location));
    setCurrentId(location.id);
    setOpenDialog(true);
  };

  const hideCode = () => setCode(false);

  const handleShow = (location) => {
    setCodeLocation(location);
    QRCodeGenerator.toDataURL(`${LOCATION_PREFIX}${location.id}`, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setCurrentId(false);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .saveLocation(values, currentId)
      .then(() => bmapi.getLocations().then(setLocations))
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "component.manageLocations.locationSaved",
            defaultMessage: "Località salvata con successo",
          })
        );
        clear();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const byName = (a, b) => a.name.localeCompare(b.name);

  const update = useCallback(() => {
    setLoading(true);
    bmapi
      .getLocations()
      .then((ls) => {
        setLocations(ls || []);
        setLoading(false);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    update();
  }, [update, businessId]);

  const createLink = (str) => (
    <Link onClick={() => setOpenDialog(true)}>{str}</Link>
  );

  return (
    <Card>
      {(!locations || loading) && <LinearProgress />}

      <Dialog open={!!code} onClose={hideCode}>
        <DialogTitle>{codeLocation.name}</DialogTitle>
        <DialogContent>
          {code && (
            <img src={code} alt="QR Code" style={{ maxWidth: "100%" }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={hideCode} variant="contained" color="primary">
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={clear}>
        <form onSubmit={handleCreate}>
          <DialogTitle>
            {currentId ? (
              <FormattedMessage
                id="component.manageLocations.editLocation"
                defaultMessage="Modifica località"
              />
            ) : (
              <FormattedMessage
                id="component.manageLocations.createLocation"
                defaultMessage="Crea località"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(form.name)}
              value={values.name}
              onChange={handleValue("name")}
              required
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.description)}
              value={values.description}
              onChange={handleValue("description")}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={clear} disabled={saving}>
              <FormattedMessage id="common.cancel" defaultMessage="Annulla" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {currentId ? (
                <FormattedMessage id="common.save" defaultMessage="Salva" />
              ) : (
                <FormattedMessage id="common.create" defaultMessage="Crea" />
              )}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      {locations &&
        (!locations.length ? (
          <CardContent>
            <Typography gutterBottom>
              <FormattedMessage
                id="component.manageLocations.noLocationRegistered"
                defaultMessage="Nessuna località ancora registrata."
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="component.manageLocations.emptyAction"
                defaultMessage="Crea la prima località cliccando sul bottone a fondo pagina o <link>qui</link>."
                values={{ link: createLink }}
              />
            </Typography>
          </CardContent>
        ) : (
          <List component="div" disablePadding>
            {locations.sort(byName).map((l, i) => (
              <React.Fragment key={l.id}>
                {i !== 0 ? <Divider component="li" /> : null}
                <LocationElement
                  location={l}
                  onDelete={handleDelete}
                  onModify={handleModify}
                  onShow={handleShow}
                  update={update}
                />
              </React.Fragment>
            ))}
          </List>
        ))}

      <FloatingActions>
        <Action
          icon={<Add />}
          label={intl.formatMessage({
            id: "component.manageLocations.createLocation",
            defaultMessage: "Crea località",
          })}
          action={() => setOpenDialog(true)}
        />
      </FloatingActions>
    </Card>
  );
}
