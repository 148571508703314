import { defineMessages } from "react-intl";

export const notifications = defineMessages({
  campaignSent: {
    id: "component.issueProduct.campaignSent",
    defaultMessage: "Hai inviato con successo la campagna {name}",
  },
  emailNotFound: {
    id: "component.issueProduct.emailNotFound",
    defaultMessage: "Email non registrata",
  },
  linkCopied: {
    id: "component.campaignActions.linkCopied",
    defaultMessage: "Link copiato con successo",
  },
  managerAdded: {
    id: "component.manageStores.managerAdded",
    defaultMessage: "Manager aggiunto con successo",
  },
  managerRemoved: {
    id: "component.manageStores.managerRemoved",
    defaultMessage: "Manager rimosso con successo",
  },
  noCredit: {
    id: "component.shoppingCardActionForm.noCredit",
    defaultMessage: "Credito non sufficiente",
  },
  storeAdded: {
    id: "component.manageLoops.storeAdded",
    defaultMessage: "Negozio aggiunto con successo",
  },
  storeRemoved: {
    id: "component.manageLoops.storeRemoved",
    defaultMessage: "Negozio rimosso con successo",
  },
  thresoldNotReached: {
    id: "component.shoppingCardActionForm.thresoldNotReached",
    defaultMessage: "Non hai raggiunto la soglia minima di spesa",
  },
  thresoldNotRespected: {
    id: "component.shoppingCardActionForm.thresoldNotRespected",
    defaultMessage: "Spesa minima non rispettata",
  },
  wrongPrizeExpiration: {
    id: "component.form.wrongPrizeExpiration",
    defaultMessage: "Scadenza dei premi non compatibile",
  },
  wrongQrCode: {
    id: "component.issueProduct.wrongQrCode",
    defaultMessage:
      "il QR Code letto non identifica l'utente ma un suo coupon o una card. Chiedere all'utente di premere {buttonName} sul proprio dispositivo",
  },
  wrongStore: {
    id: "component.formManager.wrongStore",
    defaultMessage: "Campagna appartente ad un negozio diverso",
  },
});
