import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Container } from "@material-ui/core";

import { navigation } from "../../messages";
import QrScanner from "../../ui/QrScanner";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import {
  CONSUMER_ROUTES,
  EVENT_PREFIX,
  LOCATION_PREFIX,
  UUID_REGEX_PREFIX,
} from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

export default function Scanner() {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess, stopLoading } = useBmapi();
  const [qrCode, setQrCode] = useState(null);

  // const reset = useCallback(() => {
  //   setProductInfo(null);
  //   setQrCode(null);
  // }, [setProductInfo, setQrCode]);

  const onError = useCallback(
    (error) => {
      stopLoading();
      notifyError(error);
    },
    [notifyError, stopLoading]
  );

  const sendCode = useCallback(
    (code) => {
      if (code && UUID_REGEX_PREFIX.test(code)) {
        const [, prefix, uuid] = UUID_REGEX_PREFIX.exec(code);
        console.log(prefix, uuid);
        if (prefix === LOCATION_PREFIX) {
          bmapi
            .locationCheckin(uuid)
            .then(() => {
              notifySuccess(
                intl.formatMessage({
                  id: "common.checkinDone",
                  defaultMessage: "Checkin effettuato",
                })
              );
              history.push(CONSUMER_ROUTES.HOME);
            })
            .catch((e) => onError(getErrorMessageString(e, intl)));
        } else if (prefix === EVENT_PREFIX) {
          bmapi
            .eventCheckin(uuid)
            .then(() => {
              notifySuccess(
                intl.formatMessage({
                  id: "common.checkinDone",
                  defaultMessage: "Checkin effettuato",
                })
              );
              history.push(CONSUMER_ROUTES.HOME);
            })
            .catch((e) => onError(getErrorMessageString(e, intl)));
        }
      } else {
        onError(
          intl.formatMessage({
            id: "pages.scanner.invalidQrCode",
            defaultMessage: "Codice scannerizzato non valido",
          })
        );
      }
    },
    [bmapi, history, intl, notifySuccess, onError]
  );

  useEffect(() => {
    if (qrCode) sendCode(qrCode);
  }, [qrCode, sendCode]);

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(navigation.checkin)}</Title>
      <QrScanner handleCode={setQrCode} handleError={onError} />
      {qrCode}
    </Container>
  );
}
