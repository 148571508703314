import React from "react";
import { Route } from "react-router-dom";

export default function EmptyPage({ component: Page, ...routeProps }) {
  return (
    <Route {...routeProps}>
      <Page />
    </Route>
  );
}
