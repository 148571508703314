import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function SecondaryEmail({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      label={intl.formatMessage(common.secondaryEmail)}
      margin="normal"
      name="secondary_email"
      onChange={onChange}
      required={bmapi.settings.profileRequired.includes("secondary_email")}
      type="email"
      value={value}
    />
  );
}
