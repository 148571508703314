import { defineMessages } from "react-intl";

export const form = defineMessages({
  address: {
    id: "common.address",
    defaultMessage: "Indirizzo",
  },
  autoJoin: {
    id: "common.form.autoJoin",
    defaultMessage: "Adesione automatica alla campagna",
  },
  billingPeriod: {
    id: "common.form.billingPeriod",
    defaultMessage: "Durata abbonamento",
  },
  changeIcon: {
    id: "common.form.changeIcon",
    defaultMessage: "Cambia icona",
  },
  choosePrize: {
    id: "common.form.choosePrize",
    defaultMessage: "Scegli un premio",
  },
  coordinates: {
    id: "common.form.coordinates",
    defaultMessage: "Coordinate",
  },
  city: {
    id: "common.city",
    defaultMessage: "Città",
  },
  codes_prefix: {
    id: "common.codes_prefix",
    defaultMessage: "Prefisso per i codici",
  },
  company_name: {
    id: "component.form.company_name",
    defaultMessage: "Nome società",
  },
  constraints: {
    id: "component.form.constraints",
    defaultMessage: "Vincoli di utilizzo",
  },
  coverHelp: {
    id: "common.form.coverInfo",
    defaultMessage:
      "Per una migliore resa consigliamo di caricare un'immagine in formato {ratio} con una risoluzione di {width} * {height} pixel",
  },
  currency: {
    id: "common.form.currency",
    defaultMessage: "Valuta",
  },
  custom: {
    id: "common.form.shots.custom",
    defaultMessage: "Personalizzato",
  },
  demoCampaign: {
    id: "common.form.demoCampaign",
    defaultMessage: "Campagna demo",
  },
  demoStore: {
    id: "common.form.demoStore",
    defaultMessage: "Negozio demo",
  },
  description: {
    id: "common.description",
    defaultMessage: "Descrizione",
  },
  details: {
    id: "component.form.details",
    defaultMessage: "Dettagli",
  },
  disabled: {
    id: "common.form.disabled",
    defaultMessage: "Disattivato",
  },
  discountCode: {
    id: "common.form.discountCode",
    defaultMessage: "Codice sconto",
  },
  discountCodeApplied: {
    id: "common.form.discountCodeApplied",
    defaultMessage: "Codice sconto applicato: {code}",
  },
  dynamicValue: {
    id: "common.form.dynamicValue",
    defaultMessage: "Valore dinamico",
  },
  dynamicValueHelp: {
    id: "common.form.dynamicValueHelp",
    defaultMessage:
      "Opzione non disponibile quando la distribuzione con link è attiva",
  },
  earningMinimumThreshold: {
    id: "common.form.earningMinimumThreshold",
    defaultMessage: "Differenziale di spesa",
  },
  endDateError: {
    id: "common.form.endDateError",
    defaultMessage: "Termine antecedente all'inizio della campagna",
  },
  endHour: {
    id: "common.form.endHour",
    defaultMessage: "Ora di fine evento",
  },
  expiration: {
    id: "common.form.expiration",
    defaultMessage: "Scadenza: {expirationDate, date, short}",
  },
  expirationDate: {
    id: "common.form.expirationDate",
    defaultMessage: "Data termine distribuzione",
  },
  expirationDatePass: {
    id: "common.form.endEvent",
    defaultMessage: "Data di fine evento",
  },
  externalCodes: {
    id: "common.form.externalCodes",
    defaultMessage: "Utilizzo di codici esterni",
  },
  externalCodesText: {
    id: "common.form.externalCodes.text",
    defaultMessage: "Formato testo",
  },
  externalCodesQr: {
    id: "common.form.externalCodes.qrcode",
    defaultMessage: "QR Code",
  },
  friendly_url: {
    id: "common.form.friendly_url",
    defaultMessage: "Friendly URL",
  },
  friendly_url_disabled: {
    id: "common.form.friendly_url_disabled",
    defaultMessage: "Non disponibile per piano Trial",
  },
  friendly_url_exists: {
    id: "common.form.friendly_url_exists",
    defaultMessage: "L'URL inserito non è disponibile",
  },
  friendly_url_help: {
    id: "common.form.friendly_url_help",
    defaultMessage:
      "Nome composto da 8 a 20 caratteri minuscoli, numeri e trattini",
  },
  friendly_url_info: {
    id: "common.form.friendly_url_info",
    defaultMessage:
      "Il friendly URL rappresenta l'indirizzo sul quale i tuoi clienti troveranno la pagina del tuo negozio. Potrai utilizzarlo per inserirlo nelle tue comunicazioni mail, sul web e sui social.",
  },
  gracePeriod: {
    id: "common.form.gracePeriod",
    defaultMessage: "Tolleranza",
  },
  images: {
    id: "component.form.images",
    defaultMessage: "Immagini",
  },
  initialValue: {
    id: "common.form.initialValue",
    defaultMessage: "Valore iniziale",
  },
  linkDistribution: {
    id: "common.form.linkDistribution",
    defaultMessage: "Distribuzione con link",
  },
  logo_add: {
    id: "common.form.logo_add",
    defaultMessage: "Aggiungi un logo",
  },
  logo_big: {
    id: "common.form.logo_big",
    defaultMessage: "Logo URL",
  },
  logo_info: {
    id: "common.form.logo_info",
    defaultMessage:
      "Il logo URL rappresenta l'indirizzo del logo che verrà visualizzato sulle pagine del tuo negozio. Per copiarlo basta andare una pagina web dove è visibile il tuo logo, cliccarvi sopra con il pulsante destro del tuo mouse e scegliere 'copia indirizzo immagine' (le istruzioni possono variare a seconda del browser usato e la configurazione del mouse)",
  },
  logoHelp: {
    id: "common.form.logoHelp",
    defaultMessage:
      "Per una resa migliore consigliamo un logo a sfondo trasparente",
  },
  managerRestriction: {
    id: "common.form.managerRestriction",
    defaultMessage: "Manager restrictions",
  },
  mainEvent: {
    id: "common.form.mainEvent",
    defaultMessage: "Evento principale di riferimento",
  },
  minExpiration: {
    id: "common.form.minExpiration",
    defaultMessage:
      "Scadenza minima necessaria: {expirationDate, date, medium}",
  },
  mobile: {
    id: "common.mobile",
    defaultMessage: "Telefono",
  },
  monthsNum: {
    id: "common.monthsNum",
    defaultMessage: "{months, plural, one {# mese} other {# mesi}}",
  },
  name: {
    id: "common.name",
    defaultMessage: "Nome",
  },
  none: {
    id: "common.form.none",
    defaultMessage: "Nessuno",
  },
  noPeriod: {
    id: "common.form.usePeriod.noPeriod",
    defaultMessage: "Nessun periodo",
  },
  noPrize: {
    id: "common.form.noPrizes",
    defaultMessage: "Nessun premio assegnabile",
  },
  order_summary: {
    id: "form.order_summary",
    defaultMessage: "Riepilogo ordine",
  },
  plan: {
    id: "common.form.plan",
    defaultMessage: "Tipo di abbonamento",
  },
  plan_help: {
    id: "common.form.plan_help",
    defaultMessage: "La fatturazione verrà effettuata al pagamento",
  },
  plan_trial: {
    id: "common.form.plan_trial",
    defaultMessage:
      "Sarà possibile effettuare upgrade alle versioni sbloccate prima della scadenza, dando continuità alle campagne già impostate",
  },
  preview: {
    id: "form.preview",
    defaultMessage: "Anteprima",
  },
  price: {
    id: "common.form.price",
    defaultMessage: "Prezzo",
  },
  primary_color: {
    id: "common.form.primary_color",
    defaultMessage: "Colore primario del tema",
  },
  prizeDetail: {
    id: "common.form.prizeDetail",
    defaultMessage:
      "{threshold, plural, one {# punto} other {# punti}} = {prize}",
  },
  productLimitDateError: {
    id: "common.form.productLimitDateError",
    defaultMessage: "Scadenza prima della fine della campagna",
  },
  productsName: {
    id: "common.form.productsName",
    defaultMessage: "Nome dei prodotti",
  },
  program_name: {
    id: "common.program_name",
    defaultMessage: "Nome del programma di affiliazione",
  },
  province: {
    id: "common.province",
    defaultMessage: "Provincia",
  },
  quantum: {
    id: "common.form.quantum",
    defaultMessage: "Scaglione di conversione spesa -> punti",
  },
  quantumHelp: {
    id: "common.form.quantumInfo",
    defaultMessage:
      "Scaglione di assegnazione dei punti (ogni XX euro di spesa assegna i punti)",
  },
  rate: {
    id: "common.form.rate",
    defaultMessage: "Tasso di conversione spesa -> punti",
  },
  rateHelp: {
    id: "common.form.rateInfo",
    defaultMessage:
      "Tasso di conversione spesa -> punti (es: quanti euro per guadagnare un punto)",
  },
  region: {
    id: "common.region",
    defaultMessage: "Regione",
  },
  shots: {
    id: "common.form.shots",
    defaultMessage: "Numero di utilizzi",
  },
  shotsCustom: {
    id: "common.form.shotsCustom",
    defaultMessage: "Numero di utilizzi personalizzato",
  },
  singleUse: {
    id: "common.form.shots.single",
    defaultMessage: "Uso singolo",
  },
  startDate: {
    id: "common.form.startDate",
    defaultMessage: "Data di inizio campagna",
  },
  startDatePass: {
    id: "common.form.startEvent",
    defaultMessage: "Data di inizio evento",
  },
  startHour: {
    id: "common.form.startHour",
    defaultMessage: "Ora di inizio evento",
  },
  storesNum: {
    id: "form.storesNum",
    defaultMessage: "{stores, plural, one {# negozio} other {# negozi}}",
  },
  storesQuantity: {
    id: "form.storesQuantity",
    defaultMessage: "Quantità di negozi",
  },
  threshold: {
    id: "common.form.threshold",
    defaultMessage: "Punti",
  },
  tosConsumerUrl: {
    id: "common.form.tosConsumerUrl",
    defaultMessage: "URL dei termini di servizio per i clienti",
  },
  tosMerchantUrl: {
    id: "common.form.tosMerchantUrl",
    defaultMessage: "URL dei termini di servizio per i manager",
  },
  type: {
    id: "common.form.type",
    defaultMessage: "Tipo di campagna",
  },
  unlimited: {
    id: "common.form.shots.unlimited",
    defaultMessage: "Illimitati",
  },
  useFrequency: {
    id: "common.form.useFrequency",
    defaultMessage: "Numero massimo utilizzi nel periodo",
  },
  usePeriod: {
    id: "common.form.usePeriod",
    defaultMessage: "Periodo",
  },
  usePeriodHelp: {
    id: "common.form.usePeriodHelpText",
    defaultMessage: "Periodo di tempo",
  },
  value: {
    id: "common.form.value",
    defaultMessage: "Valore",
  },
  valueSingle: {
    id: "common.form.valueSingle",
    defaultMessage: "Valore del singolo utilizzo",
  },
  vatNumber: {
    id: "common.vatNumber",
    defaultMessage: "Partita IVA",
  },
  waiting: {
    id: "common.form.waitingList",
    defaultMessage: "Lista d'attesa",
  },
  zip: {
    id: "common.zip",
    defaultMessage: "CAP",
  },
});
