import Validator from "validator";
import trianglify from "trianglify";

import { createBgGradient } from "./colors";
import { API_ERROR, BMAPI_ERROR, ERRORS, HTTP } from "./constants";

export function validateRoles(roleWanted, userRole) {
  return Array.isArray(roleWanted)
    ? roleWanted.includes(userRole)
    : roleWanted === userRole;
}

export function validateUUID(id) {
  return Validator.isUUID(id);
}

export function validateEmail(e) {
  return Validator.isEmail(e);
}

export function download(blob, filename = "download") {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.download = filename || "download";
  document.body.appendChild(link);

  const clickHandler = () => {
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.removeEventListener("click", clickHandler);
    }, 150);
  };

  link.addEventListener("click", clickHandler, false);
  link.click();
  return link;
}

export function checkTokenExpired(error) {
  return (
    error.httpCode === 401 &&
    error.code === ERRORS.TOKEN_NOT_VALID &&
    error.message.includes("ID token has expired at")
  );
}

export class BmapiError extends Error {
  constructor(message, settings, ...params) {
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BmapiError);
    }

    this.name = BMAPI_ERROR;
    this.settings = settings;
  }
}

class ApiError extends Error {
  constructor(httpCode, code, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = API_ERROR;
    this.code = code;
    this.httpCode = httpCode;
    this.message = message;
  }
}

export function handleError(response) {
  return response.json().then((data) => {
    throw new ApiError(data.HTTPCode, data.Code, data.Message);
  });
}

export function handleResponse(response) {
  if (!response.ok) return handleError(response);

  const contentType = response.headers.get("content-type") || "";
  if (contentType.includes("application/json")) {
    return response.json();
  } else if (contentType.includes("text/plain")) {
    return response.json();
  } else if (contentType.includes("text/csv")) {
    return response.blob();
  } else if (contentType.includes("image/png")) {
    return response.blob();
  }
  return response.blob();
}

export function handleDownload(response) {
  return response.ok ? response.blob().then(download) : handleError(response);
}

export async function fetch(url, method = HTTP.GET, body, headers) {
  return window
    .fetch(url, {
      method,
      headers: new Headers(headers),
      ...(body ? { body } : {}),
      credentials: "include",
    })
    .then(handleResponse);
}

export function createBg(seed = null, color) {
  const pattern = trianglify({
    height: 400,
    width: 640,
    seed: seed,
    xColors: createBgGradient(color),
    yColors: "match",
    variance: 1,
  });
  return pattern.toCanvas().toDataURL();
}

export function onlyInt(e) {
  if (!/^[0-9]$/.test(e.key) && e.key !== "Enter") {
    e.preventDefault();
  }
}

export function onlyNumbers(e) {
  if (!/^[0-9,.]$/.test(e.key) && e.key !== "Enter") {
    e.preventDefault();
  }
}

export function objToParams(obj = {}) {
  return obj && Object.entries(obj).length
    ? `?${Object.entries(obj)
        .map((p) => p.join("="))
        .join("&")}`
    : "";
}

export function composeUrl(url, apiUrl, params, query) {
  const fullUrl = url.includes("://") ? url : `${apiUrl}${url}`;
  const keys = Object.keys(params);

  return (
    (keys.length
      ? fullUrl.replace(
          new RegExp(keys.map((k) => `{${k}}`).join("|"), "gi"),
          (matched) => params[matched.slice(1, -1)]
        )
      : fullUrl) + objToParams(query)
  );
}

function loadConf(tenant, env) {
  try {
    const conf = require(`../configurations/${tenant}${
      env ? `.${env}` : ""
    }.json`);
    console.info(`Configuration: ${tenant}.${env || "default"}`);
    return conf;
  } catch {
    return {};
  }
}

export function createSettings(tenant, env) {
  const envConf = loadConf(tenant, env);
  const tenantConf = loadConf(tenant);
  const defaultConf = {
    ...loadConf("_default"),
    ...loadConf("_default", env),
  };
  return {
    common: {
      ...defaultConf,
      ...tenantConf,
      ...envConf,
    },
    consumer: {
      ...defaultConf,
      ...defaultConf._consumer,
      ...tenantConf,
      ...tenantConf._consumer,
      ...envConf,
      ...envConf._consumer,
    },
    manager: {
      ...defaultConf,
      ...defaultConf._manager,
      ...tenantConf,
      ...tenantConf._manager,
      ...envConf,
      ...envConf._manager,
    },
  };
}

function loadTheme(theme) {
  try {
    const conf = require(`../configurations/themes/${theme}.json`);
    console.info(`Theme: ${theme}`);
    return conf;
  } catch {
    return {};
  }
}

export function createThemeConf(theme, app) {
  const customTheme = loadTheme(theme);
  const defaultTheme = loadTheme("_default");
  return {
    ...defaultTheme,
    ...customTheme,
    ...(customTheme[`_${app}`] ? customTheme[`_${app}`] : {}),
  };
}

export function getData(key, empty = {}) {
  return JSON.parse(localStorage.getItem(key)) || empty;
}

export function setData(key, data, empty) {
  const newData = {
    ...getData(key, empty),
    ...data,
  };
  localStorage.setItem(key, JSON.stringify(newData));
  return newData;
}

export function parseTokenId(token_id) {
  return JSON.parse(
    window.atob(token_id.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
  );
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default {
  checkTokenExpired,
  composeUrl,
  createBg,
  createBgGradient,
  createSettings,
  createThemeConf,
  download,
  fetch,
  getData,
  handleDownload,
  handleError,
  handleResponse,
  objToParams,
  onlyNumbers,
  parseTokenId,
  setData,
  uuidv4,
  validateEmail,
  validateRoles,
  validateUUID,
};
