import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Beenhere, Menu as MenuIcon, Slideshow } from "@material-ui/icons";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../utils/constants";
import styles from "../utils/styles";
import { navigation } from "../messages";
import LanguageSelector from "./LanguageSelector";

export default function TopBarConsumer({ links, logo, actions }) {
  const { bmapi, notifySuccess, userId } = useBmapi();
  const intl = useIntl();
  const classes = styles.useStyles();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [checkin, setCheckin] = useState(false);
  const [eventCheckin, setEventCheckin] = useState(false);

  const closeMenu = () => setIsMenuVisible(false);
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const checkoutLocation = () => {
    setCheckin(false);
    bmapi.locationCheckout().then(() => {
      notifySuccess(
        intl.formatMessage({
          id: "common.checkoutDone",
          defaultMessage: "Effettuato chekout",
        })
      );
    });
  };

  const checkoutEvent = () => {
    setEventCheckin(false);
    bmapi.eventCheckout().then(() => {
      notifySuccess(
        intl.formatMessage({
          id: "common.checkoutDone",
          defaultMessage: "Effettuato chekout",
        })
      );
    });
  };

  useEffect(() => {
    bmapi.getLocationCheckin().then(setCheckin);
    bmapi.getEventCheckin().then(setEventCheckin);
  }, [bmapi]);

  return (
    <AppBar position="sticky" className={classes.topBar}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Logo logo={logo} link={CONSUMER_ROUTES.HOME} />
        </div>
        {checkin && (
          <Tooltip
            title={intl.formatMessage({
              id: "common.doCheckout",
              defaultMessage: "Effettua checkout",
            })}
            arrow
          >
            <IconButton color="inherit" onClick={checkoutLocation}>
              <Beenhere />
            </IconButton>
          </Tooltip>
        )}
        {eventCheckin && (
          <Tooltip
            title={intl.formatMessage({
              id: "common.doCheckout",
              defaultMessage: "Effettua checkout",
            })}
            arrow
          >
            <IconButton color="inherit" onClick={checkoutEvent}>
              <Slideshow />
            </IconButton>
          </Tooltip>
        )}
        {userId ? (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Menu"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <React.Fragment>
            <Button component={RouterLink} to={CONSUMER_ROUTES.SIGN_IN}>
              {intl.formatMessage(navigation.enter)}
            </Button>
            <LanguageSelector />
          </React.Fragment>
        )}
      </Toolbar>
      <MainMenu
        open={isMenuVisible}
        onClose={closeMenu}
        links={links}
        actions={actions}
        logoImage={logo}
        anchor="right"
      />
    </AppBar>
  );
}
