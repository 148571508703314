import { defineMessages } from "react-intl";

import { PRODUCTS } from "../utils/constants";

export const types = {
  [PRODUCTS.COUPON_DISCOUNT_PERC]: defineMessages({
    description: {
      id: "type.couponDiscount.description",
      defaultMessage:
        "Sconto % che può essere usato una o più volte secondo regole predefinite (tipicamente su categorie specifiche di prodotti/servizi e/o con una spesa sopra una determinata soglia) fino alla data di scadenza",
    },
  }),
  [PRODUCTS.COUPON_DISCOUNT_VALUE]: defineMessages({
    description: {
      id: "type.couponValue.description",
      defaultMessage:
        "Sconto assoluto che può essere usato una o più volte secondo regole predefinite (tipicamente su categorie specifiche di prodotti/servizi e/o con una spesa sopra una determinata soglia) fino alla data di scadenza",
    },
  }),
  [PRODUCTS.COUPON_SIMPLE]: defineMessages({
    description: {
      id: "type.couponSimple.description",
      defaultMessage:
        "Usato per acquistare/accedere categorie (specifiche  o meno) di beni/servizi gratuitamente o ad un prezzo determinato che può essere usato una volta sola secondo regole predefinite",
    },
  }),
  [PRODUCTS.COUPON_MULTISHOT]: defineMessages({
    description: {
      id: "type.couponMulti.description",
      defaultMessage:
        "Usato per acquistare/accedere ‘N’ volte categorie specifiche di beni/servizi ad un prezzo prefissato secondo regole predefinite",
    },
  }),
  [PRODUCTS.EARNING_CARD]: defineMessages({
    description: {
      id: "type.earningCard.description",
      defaultMessage:
        "Consente di accumulare punti secondo regole legate a determinati comportamenti di spesa; raggiunte delle soglie prestabilite, è possibile riscattare i punti per premi predefiniti",
    },
  }),
  [PRODUCTS.EVENT_PASS]: defineMessages({
    description: {
      id: "type.eventPass.description",
      defaultMessage:
        "Usato per accedere (a pagamento o meno) ad un evento singolo o ad eventi facenti parte di una manifestazione con la possibilità di creare calendari della manifestazione e gestire prenotazioni e code di accesso",
    },
  }),
  [PRODUCTS.PROVISIONING_CARD]: defineMessages({
    description: {
      id: "type.provisioningCard.description",
      defaultMessage:
        "Da possibilità di consuntivare l'utilizzo di un servizio (con possibilità di definire dei vincoli di utilizzo) fino a che i crediti caricati sulla carta vengano esauriti consumato o la data di scadenza è stata raggiunta",
    },
  }),
  [PRODUCTS.SHOPPING_CARD]: defineMessages({
    description: {
      id: "type.shoppingCard.description",
      defaultMessage:
        "Da possibilità di spendere un importo predefinito per l’acquisto di un determinato tipo di beni / servizi fino a quando l’importo caricato sulla carta è stato consumato o la data di scadenza è stata raggiunta",
    },
  }),
};
