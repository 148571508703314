import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Toolbar,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";
import { BUSINESS_TYPES, MANAGER_ROUTES } from "../utils/constants";

function List({ els, handleChange, selected, title }) {
  return (
    <React.Fragment>
      {els.length > 0 && (
        <ListSubheader style={{ backgroundColor: "white" }}>
          {title}
        </ListSubheader>
      )}
      {els.map((b) => (
        <MenuItem
          key={b.id}
          onClick={() => handleChange(b)}
          selected={b.id === selected.id}
        >
          {b.name}
        </MenuItem>
      ))}
    </React.Fragment>
  );
}

export default function TopBarManager({ links, logo, actions }) {
  const { bmapi, businesses, startLoading, stopLoading, userId } = useBmapi();
  const intl = useIntl();
  const classes = styles.useStyles();
  const [selected, setSelected] = useState(bmapi.getUserInfo().business);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => setIsMenuVisible(false);
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (business) => {
    startLoading();
    bmapi.changeBusiness(business).then(stopLoading);
    setSelected(business);
    handleClose();
  };

  const alpha = (a, b) => a.name.localeCompare(b.name);

  const loops = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.LOOP && b.tenant_loop_default)
    .sort(alpha);
  const subscriptions = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.LOOP && !b.tenant_loop_default)
    .sort(alpha);
  const stores = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.MERCHANT)
    .sort(alpha);

  return (
    <AppBar position="sticky" className={classes.topBar}>
      <Toolbar>
        {!!userId && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        )}
        <div style={{ flex: "1 1 auto", margin: "0 5px" }}>
          <Logo logo={logo} link={MANAGER_ROUTES.HOME} />
        </div>
        {!bmapi.needsPrivacy() && businesses?.length > 0 && (
          <div style={{ flex: "0 0 auto" }}>
            {businesses?.length > 1 ? (
              <React.Fragment>
                <Button
                  onClick={handleClick}
                  endIcon={<ArrowDropDownIcon />}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  {selected.name}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted={false}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <List
                    els={loops}
                    handleChange={handleChange}
                    selected={selected}
                    title={intl.formatMessage({
                      id: "component.topbar.loops",
                      defaultMessage: "Circuiti",
                    })}
                  />
                  <List
                    els={subscriptions}
                    handleChange={handleChange}
                    selected={selected}
                    title={intl.formatMessage({
                      id: "component.topbar.subscriptions",
                      defaultMessage: "Sottoscrizioni",
                    })}
                  />
                  <List
                    els={stores}
                    handleChange={handleChange}
                    selected={selected}
                    title={intl.formatMessage({
                      id: "component.topbar.stores",
                      defaultMessage: "Negozi",
                    })}
                  />
                </Menu>
              </React.Fragment>
            ) : (
              <Typography variant="button" display="block">
                {selected.name}
              </Typography>
            )}
          </div>
        )}
      </Toolbar>
      <MainMenu
        open={isMenuVisible}
        onClose={closeMenu}
        links={links}
        actions={actions}
        logoImage={logo}
      />
    </AppBar>
  );
}
