import React from "react";
import { useIntl } from "react-intl";

import { DatePicker } from "../../DatePicker";

import { CAMPAIGNS_LABELS, PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function ExpirationDate({
  values,
  handleChange,
  productType,
  campaigns,
  campaign,
}) {
  const intl = useIntl();

  const getMaxDate = () => {
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id
      ? campaigns.find((c) => c.campaign_id === values.main_event_id)
          .expiration_date
      : new Date("2100-01-01");
  };

  const onChange = (val) => {
    handleChange("expiration_date")(val);
    if (
      (values.product_limit_date && values.product_limit_date < val) ||
      values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE
    ) {
      handleChange("product_limit_date")(val);
    }
  };

  return (
    <DatePicker
      label={intl.formatMessage(
        productType === CAMPAIGNS_LABELS.PASS
          ? form.expirationDatePass
          : form.expirationDate
      )}
      value={values.expiration_date}
      onChange={onChange}
      key="expiration_date"
      fullWidth
      autoOk
      minDate={values.start_date}
      minDateMessage={intl.formatMessage(form.endDateError)}
      maxDate={getMaxDate()}
      required
      disabled={!!campaign}
    />
  );
}
