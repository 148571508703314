import React from "react";
import { useHistory } from "react-router-dom";

import TopBarConsumer from "../ui/TopBarConsumer";
import TopBarManager from "../ui/TopBarManager";
import { useBmapi } from "../utils/bmapi-context";
import { LINK_TYPE } from "../utils/constants";

export default function Navbar() {
  const { bmapi, pages } = useBmapi();
  const history = useHistory();
  const { business } = bmapi.getUserInfo();

  const TopBar = bmapi.isConsumer() ? TopBarConsumer : TopBarManager;

  function setActive(link) {
    return {
      ...link.menu,
      active: link.url === history.location.pathname,
    };
  }

  function isAction(link) {
    return link.menu?.position === LINK_TYPE.ACTION;
  }

  function isPage(link) {
    return link.menu?.position === LINK_TYPE.PAGE;
  }

  return (
    <TopBar
      links={pages.filter(isPage).map(setActive)}
      logo={business?.logo || bmapi.logo({ small: true })}
      actions={pages.filter(isAction).map(setActive)}
    />
  );
}
