import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useBmapi } from "../../utils/bmapi-context";
import { getAvailability } from "../../utils/campaigns";
import { CONSUMER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import styles from "../../utils/styles";
import { campaign, errors } from "../../messages";

const turnDuration = 600;
const slices = 9;
const extraLoops = 5;

const messFirstLastName =
  "In assenza di Nome o Cognome il premio non potrà essere attribuito. Per cortesia provvedere a completare il profilo. Si ricorda che i dati forniti devono essere veritieri e potranno essere soggetti a verifica per assicurare il regolare svolgimento del Concorso. Il numero di telefono può essere d’aiuto per ricevere supporto o in fase di verifica.";
const messPhone =
  "Si ricorda che i dati forniti devono essere veritieri e potranno essere soggetti a verifica per assicurare il regolare svolgimento del Concorso. Il numero di telefono può essere d’aiuto per ricevere supporto o in fase di verifica.";
const messDefault =
  "Si ricorda che i dati forniti devono essere veritieri e potranno essere soggetti a verifica per assicurare il regolare svolgimento del Concorso.";

export default function Play() {
  const { campaignId, productId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const classes = styles.useStyles();
  const wheelEl = useRef(null);
  const [started, setStarted] = useState(false);
  const [result, setResult] = useState(undefined);
  const [prize, setPrize] = useState(undefined);
  const [bg] = useState(campaign.image_url || bmapi.createBg(campaign.name));

  const handleResult = (result) => {
    setResult(result.winner);
    history.replace(
      CONSUMER_ROUTES.PLAY_RESULT.replace(":campaignId", campaignId)
    );
    if (result.winner && result.prize_name && result.prize_name !== "") {
      bmapi
        .getCampaign(result.prize_name)
        .then((rescampaign) => {
          if (rescampaign && rescampaign.campaign) {
            setPrize(rescampaign.campaign);
          }
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    }
  };

  const handleResponse = useCallback((animation, result) => {
    const correction = ((Math.random() - 0.5) * 0.8) / slices;
    const fail = Math.ceil(Math.random() * (slices - 1));
    const offset = (result.winner ? 0 : fail) / slices + correction;

    const current = animation.effect.getComputedTiming().progress;
    const arrive = extraLoops + offset;
    const duration = (arrive - current) * (turnDuration * 1.5);

    wheelEl.current
      .animate(
        [
          { transform: `rotate(${current}turn)` },
          { transform: `rotate(${arrive}turn)` },
        ],
        { duration, fill: "forwards", easing: "ease-out" }
      )
      .finished.then(() => handleResult(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkProfile = () => {
    const udata = bmapi.userData;
    if (
      !udata ||
      !udata.complete_name ||
      udata.complete_name.trim() === "" ||
      !udata.last_name ||
      udata.last_name.trim() === ""
    ) {
      return messFirstLastName;
    }
    if (!udata.mobile || udata.mobile.trim() === "") {
      return messPhone;
    }
    return messDefault;
  };

  useEffect(() => {
    if (!started) {
      if (productId === "result") {
        return history.push(
          CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaignId)
        );
      }
      setStarted(true);
      const animation = wheelEl.current.animate(
        [{ transform: "rotate(0turn)" }, { transform: "rotate(1turn)" }],
        { duration: turnDuration, iterations: Infinity }
      );
      bmapi.getUserProductsStats().then((res) => {
        const stats = res.find((s) => s.campaign.id === campaignId) || false;
        const availability = stats ? getAvailability(stats) : 0;
        if (availability <= 0) {
          return history.push(
            CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaignId)
          );
        } else {
          bmapi
            .doInstantPlay(productId)
            .then((result) => handleResponse(animation, result))
            .catch((e) => {
              console.log(e);
              notifyError(intl.formatMessage(errors.genericError));
              return history.push(
                CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaignId)
              );
            });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmapi, handleResponse, started, wheelEl]);

  return (
    <div>
      <div className={classes.spinningWheelContainer}>
        <div ref={wheelEl} className={classes.spinningWheel} />
        <div className={classes.spinningWheelFrame}></div>
        <div className={classes.spinningWheelMessage}>
          <Dialog open={typeof result === "boolean" && result}>
            <DialogTitle>Hai vinto!</DialogTitle>
            <DialogContent>
              {prize && (
                <Card style={{ marginBottom: 15 }}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={prize.image_url || bg}
                  />
                  <CardHeader
                    title={prize.name}
                    titleTypographyProps={{ variant: "h5" }}
                  />
                </Card>
              )}
              <DialogContentText>{checkProfile()}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                component={RouterLink}
                to={CONSUMER_ROUTES.HOME}
                color="inherit"
              >
                Vai alla home page
              </Button>
              <Button
                component={RouterLink}
                to={CONSUMER_ROUTES.ACCOUNT}
                color="inherit"
              >
                Completa il profilo
              </Button>
            </DialogActions>
          </Dialog>
          {typeof result === "boolean" && !result && (
            <Alert severity="error" variant="filled" elevation={6}>
              <AlertTitle>Ritenta, sarai più fortunato</AlertTitle>
              <Link
                component={RouterLink}
                to={CONSUMER_ROUTES.HOME}
                color="inherit"
              >
                <strong>Torna alla home page</strong>
              </Link>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}
