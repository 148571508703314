import React from "react";
import { Route } from "react-router-dom";

import Navbar from "./Navbar";
import Content from "../ui/Content";

export default function PageWrapper({ component: Page, ...routeProps }) {
  return (
    <Route {...routeProps}>
      <Navbar />
      <Content>
        <Page />
      </Content>
    </Route>
  );
}
