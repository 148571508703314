import React from "react";
import { useIntl } from "react-intl";

import { MenuItem, TextField } from "@material-ui/core";

import { common, form } from "../../../messages";

export default function UsePeriod({ values, handleChange, campaign }) {
  const intl = useIntl();

  return (
    <TextField
      name="use_period"
      label={intl.formatMessage(form.usePeriodHelp)}
      value={values.use_period}
      onChange={handleChange("use_period")}
      select
      fullWidth
      required={!!values.use_frequency}
      margin="normal"
      disabled={!!campaign}
    >
      {[
        { label: intl.formatMessage(form.noPeriod), value: "" },
        { label: intl.formatMessage(common.day), value: "-1" },
        { label: intl.formatMessage(common.week), value: "-7" },
        { label: intl.formatMessage(common.month), value: "-30" },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
