import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { Box, Button, Container } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";

export default function Content({ content: cont }) {
  const { bmapi } = useBmapi();
  const { content = cont } = useParams();
  const history = useHistory();
  const [module, setModule] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const priority = [
      `${bmapi.tenant}/${content}.${intl.locale.slice(0, 2)}`,
      `${bmapi.tenant}/${content}`,
      `${content}.${intl.locale.slice(0, 2)}`,
      `${content}`,
    ];

    import(`./contents/${priority[0]}`)
      .catch(() => import(`./contents/${priority[1]}`))
      .catch(() => import(`./contents/${priority[2]}`))
      .catch(() => import(`./contents/${priority[3]}`))
      .then(setModule);
  }, [bmapi, content, intl.defaultLocale, intl.locale]);

  return module ? (
    <Container maxWidth={module?.size || "md"}>
      {module.default()}
      <Box my={2}>
        {window.history.length > 1 ? (
          <Button onClick={history.goBack} startIcon={<ArrowBack />}>
            <FormattedMessage id="common.back" defaultMessage="Indietro" />
          </Button>
        ) : (
          <Button onClick={window.close}>
            <FormattedMessage id="common.close" defaultMessage="Chiudi" />
          </Button>
        )}
      </Box>
    </Container>
  ) : (
    <React.Fragment />
  );
}
