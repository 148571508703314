import React, { useState } from "react";
import { useIntl } from "react-intl";

import { InputAdornment, MenuItem, TextField } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCTS, PRODUCT_TYPES } from "../../../utils/constants";
import { common, form } from "../../../messages";

export default function ShotNumber({ values, handleChange, campaign }) {
  const intl = useIntl();
  const [shots, setShots] = useState(values.shot_number || "1");

  const handleShots = (e) => {
    setShots(e.target.value);
    handleChange("shot_number")(
      e.target.value > -2 ? e : { target: { value: "1" } }
    );
  };

  return (
    [PRODUCT_TYPES.CAMPAIGN_COUPON].includes(values.type) &&
    ![PRODUCTS.COUPON_SIMPLE].includes(values.front_end_type) && (
      <React.Fragment>
        {!!campaign || (
          <TextField
            name="shots"
            label={intl.formatMessage(form.shots)}
            value={shots}
            onChange={handleShots}
            select
            fullWidth
            margin="normal"
          >
            {[
              { value: "-1", label: intl.formatMessage(form.unlimited) },
              { value: "1", label: intl.formatMessage(form.singleUse) },
              { value: "2" },
              { value: "3" },
              { value: "5" },
              { value: "7" },
              { value: "10" },
              { value: "15" },
              { value: "20" },
              { value: "25" },
              { value: "30" },
              { value: "-2", label: intl.formatMessage(form.custom) },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label || option.value}
              </MenuItem>
            ))}
          </TextField>
        )}
        {(!!campaign || shots === "-2") && (
          <NumberInput
            name="shot_number"
            label={intl.formatMessage(campaign ? form.shots : form.shotsCustom)}
            value={values.shot_number}
            onChange={handleChange("shot_number")}
            fullWidth
            required
            margin="normal"
            max={25}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {intl.formatMessage(common.times)}
                </InputAdornment>
              ),
            }}
            disabled={!!campaign}
          />
        )}
      </React.Fragment>
    )
  );
}
