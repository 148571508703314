import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { form } from "../../../messages";

export default function LinkDistribution({ values, handleChange }) {
  const intl = useIntl();

  const onChange = (_, v) => {
    handleChange("link_distribution")(v);
    if (v) handleChange("dynamic_value")(false);
    handleChange("max_products_per_user")("1");
  };

  return (
    <FormControl margin="normal">
      <FormControlLabel
        control={
          <Switch
            checked={values.link_distribution}
            color="primary"
            onChange={onChange}
            key="link_distribution"
            name="link_distribution"
          />
        }
        label={intl.formatMessage(form.linkDistribution)}
      />
    </FormControl>
  );
}
