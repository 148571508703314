import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
} from "@material-ui/core";

import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import Title from "../../ui/Title";
import { useCampaigns } from "../../utils/campaigns";
import LimitProgress from "../../ui/LimitProgress";
import { BUSINESS_TYPES } from "../../utils/constants";
import { common, subscriptions } from "../../messages";

export default function ManageSubscription() {
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const [businesses, setBusinesses] = useState(false);
  const { campaigns, loadCampaigns } = useCampaigns();

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  useEffect(() => {
    Promise.all([bmapi.getUserBusiness(), bmapi.getSubscriptionStats()])
      .then(([bs, stats]) => {
        setBusinesses(
          bs.filter(
            (b) => b.type === BUSINESS_TYPES.MERCHANT && b.status === 0
          ) || []
        );
        console.log(stats);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError]);

  return (
    <Container maxWidth="sm">
      <Title>
        {intl.formatMessage({
          id: "pages.manageSubscription.title",
          defaultMessage: "Impostazioni sottoscrizione",
        })}
      </Title>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {intl.formatMessage(subscriptions.limits)}
          </Typography>
          {bmapi.subscription.plan_limits.max_business > 0 && (
            <Box mt={2}>
              <Typography>{intl.formatMessage(common.stores)}</Typography>
              <LimitProgress
                value={businesses?.length || 0}
                limit={bmapi.subscription.plan_limits.max_business}
              />
            </Box>
          )}
          {bmapi.subscription.plan_limits.max_campaigns > 0 && (
            <Box mt={2}>
              <Typography>{intl.formatMessage(common.campaigns)}</Typography>
              <LimitProgress
                value={campaigns?.length || 0}
                limit={bmapi.subscription.plan_limits.max_campaigns}
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <Box mt={2}>
        <Card>
          <CardContent>
            {intl.formatMessage(subscriptions.changePlanInfo, {
              email: (
                <Link key="info" href="mailto:info@liberacta.com">
                  info@liberacta.com
                </Link>
              ),
            })}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
