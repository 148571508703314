import React from "react";
import { useIntl } from "react-intl";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { common, product } from "../../../messages";

export default function ProductLifespan({
  values,
  handleChange,
  productType,
  campaign,
}) {
  const intl = useIntl();

  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <NumberInput
        name="product_lifespan"
        label={intl.formatMessage(product[productType].productLifespan)}
        value={values.product_lifespan}
        onChange={handleChange("product_lifespan")}
        key="product_lifespan"
        fullWidth
        margin="normal"
        required={!values.product_limit_date}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {intl.formatMessage(common.months)}
            </InputAdornment>
          ),
        }}
        disabled={!!campaign}
      />
    )
  );
}
