import React from "react";

import { FormControl, FormGroup, Typography } from "@material-ui/core";

import styles from "../../../utils/styles";

export default function FormSection({ children, title }) {
  const classes = styles.useStyles();

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      {title && (
        <Typography component="legend" variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      <FormGroup>{children}</FormGroup>
    </FormControl>
  );
}
