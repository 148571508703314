import React, { useState } from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { onlyNumbers, onlyInt } from "../utils/utils";

export default function NumberInput({
  value,
  onChange,
  float = false,
  min = 0,
  max,
  format = true,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const intl = useIntl();

  const handleChange = (e) => {
    if (e.target.value === "" || e.target.validity.valid) onChange(e);
  };

  const formatValue = () => (value ? intl.formatNumber(value) : "");

  return (
    <TextField
      value={focused || !format ? value : formatValue()}
      onChange={handleChange}
      type={focused ? "number" : "text"}
      inputProps={{
        onKeyPress: float ? onlyNumbers : onlyInt,
        min,
        max,
        step: float ? 0.01 : 1,
        lang: intl.locale,
        onFocus: () => setFocused(true),
        onBlur: () => setFocused(false),
      }}
      {...props}
    />
  );
}
