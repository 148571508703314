import React from "react";
import { useIntl } from "react-intl";

import { TimePicker } from "../../DatePicker";

import { PRODUCT_TYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function StartHour({ values, handleChange }) {
  const intl = useIntl();

  return (
    values.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS && (
      <TimePicker
        label={intl.formatMessage(form.startHour)}
        value={values.start_hour}
        onChange={handleChange("start_hour")}
        key="start_hour"
        fullWidth
        autoOk
        clearable
        required={!!values.end_hour}
      />
    )
  );
}
