import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import QrReader from "react-qr-reader";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import {
  MANAGER_ROUTES,
  UUID_LENGTH,
  UUID_REGEX_PREFIX,
} from "../utils/constants";

export function isCompatibleChar(char) {
  return /^[0-9a-zA-Z-@']$/.test(char);
}

export default function QrScanner({
  handleError,
  handleCode,
  infoText,
  back = true,
  acceptAll = false,
  excludeKeyboard = false,
}) {
  const history = useHistory();
  const intl = useIntl();
  const [code, setCode] = useState("");
  const [scanner, setScanner] = useState(false);
  const [stream, setStream] = useState(false);

  const handleInput = useCallback(
    (e) => {
      if (acceptAll || isCompatibleChar(e.key)) {
        setCode((code) => ((code || "") + e.key).slice(-UUID_LENGTH));
      }
    },
    [acceptAll]
  );

  const handleScan = (c) => {
    if (c !== code) setCode(c);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCode(code);
    setCode("");
  };

  useEffect(() => {
    if (excludeKeyboard) return;
    document.addEventListener("keydown", handleInput);

    return () => {
      document.removeEventListener("keydown", handleInput);
    };
  }, [handleInput, excludeKeyboard]);

  useEffect(() => {
    if (
      code &&
      (acceptAll || UUID_REGEX_PREFIX.test(code.replaceAll("'", "-")))
    ) {
      handleCode(code.replaceAll("'", "-"));
      setCode("");
    }
  }, [code, handleCode, acceptAll]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        setStream(stream);
        setScanner(true);
      })
      .catch(() =>
        handleError(
          intl.formatMessage({
            id: "component.qrScanner.noWebcam",
            defaultMessage: "Nessuna webcam disponibile",
          })
        )
      );
  }, [handleError, intl]);

  useEffect(() => {
    if (stream) stream.getTracks().forEach((track) => track.stop());
  }, [stream]);

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Grid container direction="column" alignItems="stretch">
            {infoText && (
              <Grid item>
                <Typography align="center" paragraph>
                  {infoText}
                </Typography>
              </Grid>
            )}

            {!scanner ? null : (
              <Grid
                item
                style={{
                  borderRadius: 20,
                  overflow: "hidden",
                  paddingBottom: "100%",
                }}
              >
                <QrReader
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%" }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {back && (
        <FormControl margin="normal">
          <Button
            onClick={() => {
              history.push(MANAGER_ROUTES.HOME);
            }}
            startIcon={<ArrowBackIcon />}
          >
            <FormattedMessage
              id="common.backHome"
              defaultMessage="Torna alla home"
            />
          </Button>
        </FormControl>
      )}
    </form>
  );
}
