import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";
import { form } from "../../../messages";

export default function UseFrequency({ values, handleChange, campaign }) {
  const intl = useIntl();

  return (
    <NumberInput
      name="use_frequency"
      label={intl.formatMessage(form.useFrequency)}
      value={values.use_frequency}
      onChange={handleChange("use_frequency")}
      fullWidth
      required={values.use_period !== ""}
      margin="normal"
      min={1}
      disabled={!!campaign}
    />
  );
}
