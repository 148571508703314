import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import QRCodeGenerator from "qrcode";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  AddBox,
  Apps,
  ArrowBack,
  Check,
  Clear,
  Launch,
  Lens,
  PlayArrow,
  Stop,
} from "@material-ui/icons";

import { common } from "../../messages";
import CampaignPerformance from "../../ui/CampaignPerformance";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import { EVENT_PREFIX } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import styles from "../../utils/styles";
import CampaignEventStop from "../../ui/CampaignEventStop";
import CampaignEventStart from "../../ui/CampaignEventStart";
import Ribbon from "../../ui/Ribbon";

export default function Campaign() {
  const { campaignId } = useParams();
  const classes = styles.useStyles();
  const intl = useIntl();
  const { campaigns, loadCampaigns } = useCampaigns();
  const history = useHistory();
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [performance, setPerformance] = useState(false);
  const [terms, setTerms] = useState(false);
  const [events, setEvents] = useState([]);
  const [bg, setBg] = useState(false);
  const [code, setCode] = useState(false);
  const [startingEvent, setStartingEvent] = useState(false);
  const [stoppingEvent, setStoppingEvent] = useState(false);
  const [partecipants] = useState([
    { name: "Arcangelo Delia", checkin: "9:30", checkout: false },
    {
      name: "Camillo Cristiana",
      checkin: "9:30",
      checkout: "11:30",
    },
    { name: "Serena Raffaella", checkin: false, checkout: false },
    { name: "Timoteo Narciso", checkin: false, checkout: false },
    {
      name: "Vilfredo Gualberto",
      checkin: "9:30",
      checkout: false,
    },
  ]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  useEffect(() => {
    if (campaigns) {
      setCampaign(campaigns.find((cv) => cv.campaign_id === campaignId));
    }
  }, [campaignId, campaigns]);

  useEffect(() => {
    if (campaign) {
      startLoading();

      setBg(campaign.image_url || bmapi.createBg(campaign.name));
      bmapi.getEvents(null, campaign.campaign_id).then(setEvents);
      bmapi
        .getCampaignDetails(campaign.campaign_id)
        .then(({ use_terms, performance }) => {
          setPerformance(performance);
          setTerms(use_terms);
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    }
  }, [bmapi, campaign, intl, notifyError, startLoading, stopLoading]);

  function hideCode() {
    setCode(false);
  }

  function loadCode(id) {
    startLoading();

    QRCodeGenerator.toDataURL(`${EVENT_PREFIX}${id}`, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }

  const afterStart = () => {
    setStartingEvent(false);
    bmapi.getEvents(null, campaign.campaign_id).then(setEvents);
  };

  const afterStop = () => {
    setStoppingEvent(false);
    bmapi.getEvents(null, campaign.campaign_id).then(setEvents);
  };

  return (
    !!campaign && (
      <Container maxWidth="sm">
        <CampaignEventStop
          campaign={campaign}
          events={events}
          open={stoppingEvent}
          onSuccess={afterStop}
          onCancel={() => setStoppingEvent(false)}
        />
        <CampaignEventStart
          campaign={campaign}
          open={startingEvent}
          onSuccess={afterStart}
          onCancel={() => setStartingEvent(false)}
        />

        <Dialog open={!!code} onClose={hideCode}>
          <DialogContent>
            {code && (
              <img
                src={code || ""}
                alt="QR Code"
                style={{ maxWidth: "100%" }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={hideCode} color="primary" autoFocus>
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>

        <Box my={2}>
          <Button onClick={history.goBack} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.back)}
          </Button>
        </Box>

        <Box mb={4}>
          <Card style={{ position: "relative" }}>
            {campaign.demo && (
              <Ribbon label={intl.formatMessage(common.demo)} />
            )}
            {!!bg && <CardMedia className={classes.cardMedia} image={bg} />}
            <CardHeader
              title={campaign.name}
              titleTypographyProps={{ variant: "h5" }}
              subheader={campaign.business_name}
            />
            <CardContent style={{ whiteSpace: "pre-line" }}>
              {campaign.description}
            </CardContent>
          </Card>
        </Box>

        {/* TODO: change condition with the new campaign type */}
        {campaign.name.toLowerCase().includes("corso") && (
          <Box mb={4}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h5">
                      {events.length
                        ? intl.formatMessage(common.eventRunning)
                        : intl.formatMessage(common.noEventRunning)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {!!events.length && <Lens style={{ color: "red" }} />}
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions disableSpacing>
                <Box>
                  {events.length ? (
                    <React.Fragment>
                      <Button
                        startIcon={<Stop />}
                        color="primary"
                        onClick={() => setStoppingEvent(true)}
                      >
                        {intl.formatMessage(common.endEvent)}
                      </Button>
                      <Button
                        startIcon={<Apps />}
                        color="primary"
                        onClick={() => loadCode(events[0].id)}
                      >
                        {intl.formatMessage(common.eventCode)}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      startIcon={<PlayArrow />}
                      color="primary"
                      onClick={() => setStartingEvent(true)}
                    >
                      {intl.formatMessage(common.startEvent)}
                    </Button>
                  )}
                </Box>
              </CardActions>

              <List dense>
                {partecipants.map((person) => (
                  <ListItem key={person.name}>
                    {!!events.length && (
                      <ListItemIcon>
                        {person.checkin &&
                          (person.checkout ? (
                            <Clear />
                          ) : (
                            <Check style={{ color: "green" }} />
                          ))}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={person.name}
                      secondary={
                        !!events.length &&
                        `${
                          person.checkin
                            ? person.checkin
                            : intl.formatMessage(common.notPresent)
                        }${person.checkout ? ` | ${person.checkout}` : ""}`
                      }
                    />
                    {!!events.length && !person.checkout && (
                      <ListItemSecondaryAction>
                        <IconButton edge="end">
                          {person.checkin ? <Launch /> : <AddBox />}
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>
        )}

        <Box mb={4}>
          <Card>
            <CardContent>
              <CampaignPerformance
                fromLoop={campaign.loop_campaign}
                owner={campaign.business_id === campaign.business_owner_id}
                type={campaign.type}
                performance={performance}
                terms={terms}
              />
            </CardContent>
          </Card>
        </Box>

        <Box my={2}>
          <Button onClick={history.goBack} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.back)}
          </Button>
        </Box>
      </Container>
    )
  );
}
