import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { Box, Container, Link } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useBmapi } from "../utils/bmapi-context";
import { FEATURES } from "../utils/constants";

export function Banner() {
  const { bmapi } = useBmapi();
  const [banner, setBanner] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (bmapi.can(FEATURES.BANNERS)) {
      bmapi.getBanner().then(setBanner);
    }
  }, [bmapi, location.pathname]);

  return (
    !!banner && (
      <Box align="center" key={location.pathname} my={4}>
        <Link href={banner.link} target="_blank">
          <img src={banner.image_url} style={{ maxWidth: "100%" }} />
        </Link>
      </Box>
    )
  );
}

export default function Content({ children }) {
  const { bmapi } = useBmapi();

  const daysLeft = () =>
    Math.floor(
      bmapi.settings.unverified -
        (new Date() - new Date(bmapi.user.metadata.creationTime)) / 864e5
    );

  return (
    <Box my={5} pb={10}>
      {bmapi.needsVerification() && (
        <Container maxWidth="sm">
          <Box mb={5}>
            <Alert severity="warning">
              <AlertTitle>
                <FormattedMessage
                  id="component.content.notVerifiedAccount"
                  defaultMessage="Account non verificato"
                />
              </AlertTitle>
              {typeof bmapi.settings.unverified === "number" ? (
                <FormattedMessage
                  id="component.content.certifyMessageDays"
                  defaultMessage={`Hai ancora {daysLeft} giorni per certificare il tuo indirizzo email cliccando sul link che hai ricevuto via email al momento della registrazione.`}
                  values={{ daysLeft: daysLeft() }}
                />
              ) : (
                <FormattedMessage
                  id="component.content.certifyMessage"
                  defaultMessage={`Certifica il tuo indirizzo email cliccando sul link che hai ricevuto via email al momento della registrazione.`}
                />
              )}
            </Alert>
          </Box>
        </Container>
      )}

      {bmapi.isConsumer() && <Banner />}
      {children}
      {bmapi.isConsumer() && <Banner />}
    </Box>
  );
}
