import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { fromString } from "css-color-converter";
import slugify from "slugify";

import {
  Accordion,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";

import { tiers } from "../../components/Pricing";
import { common, form, subscriptions } from "../../messages";
import FormSection from "../../ui/forms/input/FormSection";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useForm } from "../../utils/form";
import { CONSUMER_ROUTES, PLANS } from "../../utils/constants";
import {
  AddCircle,
  BrightnessLow,
  CheckCircle,
  Error,
  ExpandMore,
  Menu,
} from "@material-ui/icons";
import Logo from "../../ui/Logo";
import NumberInput from "../../ui/NumberInput";

export default function Subscribe() {
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [checkTimeout, setCheckTimeout] = useState(false);
  const [slugValid, setSlugValid] = useState(true);
  const [slugExists, setSlugExists] = useState(false);
  const [discountCode, setDiscountCode] = useState(
    bmapi.getTenantData().SC || ""
  );
  const slugInput = useRef();
  const [values, handleChange] = useForm({
    discountCode: "",
    company_name: "",
    company_email: "", // TODO: aggiungere alle api email referente?
    friendly_url: {
      default: "",
      format: (v) => slugify(v.replace(" ", "-"), { lower: true }),
    },
    logo_big: "",
    billingPeriod:
      new URLSearchParams(location.search).get("billingPeriod") || 12,
    plan: new URLSearchParams(location.search).get("plan") || "SMSTD",
    primary_color: fromString(bmapi.themeConf.primary).toHexString(),
    store_quantity: 1,
  });

  const checkInteger = (num) => {
    if (!num) return 1;
    if ((num ^ 0) === num) return num; // num is numeric
    return parseInt(num, 10);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    console.log(values);
    const newValues = {
      ...values,
      store_quantity: checkInteger(values.store_quantity),
      number_of_months: values.billingPeriod,
    };
    delete newValues.billingPeriod;
    bmapi
      .subscribe({
        ...newValues,
        logo_big:
          values.logo_big ||
          "https://storage.googleapis.com/bmarken-assets/tenants/demo/logo-consumer.svg",
      })
      .then(() => {
        history.push(CONSUMER_ROUTES.HOME);
        notifySuccess(
          intl.formatMessage({
            id: "subscribe.requestSent",
            defaultMessage: "Richiesta inoltrata con successo",
          })
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const interceptSubmit = (e) => {
    if (e.key === "Enter") {
      addDiscountCode(e);
    }
  };

  const checkSlug = () => {
    setSlugExists(false);
    if (slugInput.current.validity.valid && values.friendly_url) {
      bmapi.getSubscriptionsBySlug(values.friendly_url).then((s) => {
        setSlugValid(!s.length);
        setSlugExists(!!s.length);
      });
    } else {
      setSlugValid(false);
    }
  };

  useEffect(() => {
    if (values.plan === PLANS.TRIAL) {
      handleChange("billingPeriod")(3);
      handleChange("store_quantity")(1);
      handleChange("friendly_url")("");
    }
  }, [handleChange, values.plan]);

  useEffect(() => {
    if (values.plan !== PLANS.TRIAL) {
      handleChange("friendly_url")(
        slugify(values.company_name, { lower: true })
      );
    }
  }, [handleChange, values.company_name, values.plan]);

  useEffect(() => {
    clearTimeout(checkTimeout);
    const to = setTimeout(checkSlug, 500);
    setCheckTimeout(to);
    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.friendly_url]);

  const getDiscount = () => {
    if (discountCode.startsWith("VV") && discountCode.length > 2) return 0.05;
    if (discountCode === "CC1B" && values.plan === "SMBAS") return 0.1;
    if (discountCode === "CC2S" && values.plan === "SMSTD") return 0.15;
    if (discountCode === "CC3A" && values.plan === "SMADV") return 0.2;
    return 0;
  };

  const getDiscountValue = () => {
    return intl.formatNumber(
      getPrice(false) * getDiscount() * (values.store_quantity || 1),
      {
        style: "currency",
        currency: "EUR",
      }
    );
  };

  const getPrice = (format = true) => {
    const tier = tiers.find((t) => t.id === values.plan);
    const price = tier.pricing[values.billingPeriod];
    const discounts = tier.quantityDiscount;
    const discountedPrice = Array(+values.store_quantity || 1)
      .fill(price)
      .map((p, i) => p * (discounts[i] || discounts.slice(-1)[0]))
      .reduce((a, b) => a + b);
    return format
      ? intl.formatNumber(discountedPrice, {
          style: "currency",
          currency: "EUR",
        })
      : discountedPrice;
  };

  const getTotalPrice = (format = true) => {
    const total = getPrice(false) * (1 - getDiscount());

    return format
      ? intl.formatNumber(total, {
          style: "currency",
          currency: "EUR",
        })
      : total;
  };

  const addDiscountCode = (e) => {
    e.preventDefault();
    setDiscountCode(values.discountCode);
    handleChange("discountCode")("");
  };

  const removeDiscountCode = (e) => {
    e.preventDefault();
    setDiscountCode("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <Title backUrl={CONSUMER_ROUTES.HOME}>
          {intl.formatMessage({
            id: "title.subscribe",
            defaultMessage: "Sottoscrivi",
          })}
        </Title>
        <Box mb={4}>
          <Card>
            <CardContent>
              <FormSection
                title={intl.formatMessage(subscriptions.subscription)}
              >
                <TextField
                  name="plan"
                  label={intl.formatMessage(form.plan)}
                  value={values.plan}
                  onChange={handleChange("plan")}
                  key="plan"
                  fullWidth
                  margin="normal"
                  select
                  required
                  helperText={
                    values.plan === PLANS.TRIAL
                      ? intl.formatMessage(form.plan_trial)
                      : intl.formatMessage(form.plan_help)
                  }
                >
                  {tiers.map((tier) => (
                    <MenuItem value={tier.id} key={tier.id}>
                      {tier.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="billingPeriod"
                  label={intl.formatMessage(form.billingPeriod)}
                  value={values.billingPeriod}
                  onChange={handleChange("billingPeriod")}
                  key="billingPeriod"
                  fullWidth
                  disabled={values.plan === PLANS.TRIAL}
                  margin="normal"
                  select
                  required
                >
                  {[1, 3, 6, 12].map((months) => (
                    <MenuItem
                      value={months}
                      key={months}
                      disabled={values.plan === PLANS.TRIAL && months !== 3}
                    >
                      {intl.formatMessage(form.monthsNum, { months })}{" "}
                      {values.plan === PLANS.TRIAL && months !== 3
                        ? `[${intl.formatMessage(
                            subscriptions.notAvailableinTrial
                          )}]`
                        : ""}
                    </MenuItem>
                  ))}
                </TextField>
                <NumberInput
                  label={intl.formatMessage(form.storesQuantity)}
                  fullWidth
                  value={values.store_quantity}
                  margin="normal"
                  required
                  disabled={values.plan === PLANS.TRIAL}
                  onChange={handleChange("store_quantity")}
                  min={1}
                  max={values.plan === PLANS.TRIAL ? 1 : 100}
                />
              </FormSection>
              <FormSection
                title={intl.formatMessage(subscriptions.companyInfo)}
              >
                <TextField
                  name="company_name"
                  label={intl.formatMessage(form.company_name)}
                  value={values.company_name}
                  onChange={handleChange("company_name")}
                  key="company_name"
                  fullWidth
                  margin="normal"
                  required
                />
              </FormSection>
              <FormSection title={intl.formatMessage(subscriptions.webAppConf)}>
                <TextField
                  name="friendly_url"
                  label={intl.formatMessage(form.friendly_url)}
                  value={values.friendly_url}
                  onChange={handleChange("friendly_url")}
                  key="friendly_url"
                  fullWidth
                  margin="normal"
                  required={values.plan !== PLANS.TRIAL}
                  inputRef={slugInput}
                  disabled={values.plan === PLANS.TRIAL}
                  error={!!values.friendly_url && !slugValid}
                  helperText={
                    values.plan === PLANS.TRIAL
                      ? intl.formatMessage(form.friendly_url_disabled)
                      : slugValid
                      ? bmapi.settings.subscriptionsURL + values.friendly_url
                      : slugExists
                      ? intl.formatMessage(form.friendly_url_exists)
                      : intl.formatMessage(form.friendly_url_help)
                  }
                  InputProps={{
                    endAdornment: !!values.friendly_url && (
                      <InputAdornment position="end">
                        {slugValid ? (
                          <CheckCircle style={{ color: "#4caf50" }} />
                        ) : (
                          <Error style={{ color: "#f44336" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "^[a-z0-9](-?[a-z0-9]){7,19}$",
                  }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  {intl.formatMessage(form.friendly_url_info)}
                </Typography>

                <TextField
                  name="logo_big"
                  label={intl.formatMessage(form.logo_big)}
                  value={values.logo_big}
                  onChange={handleChange("logo_big")}
                  key="logo_big"
                  fullWidth
                  margin="normal"
                  helperText={intl.formatMessage(form.logoHelp)}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  {intl.formatMessage(form.logo_info)}
                </Typography>

                <TextField
                  name="primary_color"
                  label={intl.formatMessage(form.primary_color)}
                  value={values.primary_color}
                  onChange={handleChange("primary_color")}
                  key="primary_color"
                  fullWidth
                  margin="normal"
                  type="color"
                  required
                />
              </FormSection>
            </CardContent>
          </Card>
        </Box>
        <Box mb={4}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">
                {intl.formatMessage(form.preview)}
              </Typography>
            </AccordionSummary>
            <Box pb={4}>
              <AppBar
                position="static"
                style={{
                  background: values.primary_color,
                  color: theme.palette.getContrastText(values.primary_color),
                }}
              >
                <Toolbar>
                  <div style={{ flexGrow: 1 }}>
                    <Logo
                      logo={
                        values.logo_big ||
                        "https://storage.googleapis.com/bmarken-assets/tenants/demo/logo-consumer.svg"
                      }
                      alt={
                        values.company_name || intl.formatMessage(form.logo_add)
                      }
                    />
                  </div>
                  <IconButton edge="end" color="inherit">
                    <Menu />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Container maxWidth="sm">
                <Title>Title</Title>
                <Typography>
                  BMarkEn is an ideal tool to allow Merchants, Retailers,
                  Producers to match two basic needs: how to promote their
                  products/services and how to retain customers.
                </Typography>
                <Box align="center" mt={3}>
                  <Fab
                    variant="extended"
                    style={{
                      background: values.primary_color,
                      color: theme.palette.getContrastText(
                        values.primary_color
                      ),
                    }}
                  >
                    <BrightnessLow style={{ marginRight: 10 }} />
                    Action
                  </Fab>
                </Box>
              </Container>
            </Box>
          </Accordion>
        </Box>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {intl.formatMessage(form.order_summary)}
            </Typography>

            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {intl.formatMessage(form.monthsNum, {
                        months: values.billingPeriod,
                      })}{" "}
                      * {tiers.find((t) => t.id === values.plan).name} *{" "}
                      {intl.formatMessage(form.storesNum, {
                        stores: values.store_quantity || 1,
                      })}
                    </TableCell>
                    <TableCell align="right">{getPrice()}</TableCell>
                  </TableRow>

                  {values.plan !== PLANS.TRIAL &&
                    (discountCode ? (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {intl.formatMessage(form.discountCodeApplied, {
                            code:
                              discountCode === bmapi.getTenantData().SC
                                ? intl.formatMessage(common.referrer)
                                : discountCode,
                          })}{" "}
                          [
                          <Link onClick={removeDiscountCode}>
                            {intl.formatMessage(common.delete)}
                          </Link>
                          ]
                        </TableCell>
                        <TableCell align="right">
                          {getDiscount() * 100}% [{getDiscountValue()}]
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={2}
                          padding="none"
                        >
                          <TextField
                            name="discountCode"
                            label={intl.formatMessage(form.discountCode)}
                            value={values.discountCode}
                            onChange={handleChange("discountCode")}
                            onKeyPress={interceptSubmit}
                            key="discountCode"
                            fullWidth
                            margin="normal"
                            variant="filled"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={addDiscountCode}>
                                    <AddCircle />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>{intl.formatMessage(common.total)}</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>{getTotalPrice()}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <FormControl fullWidth margin="normal">
              <Button variant="contained" color="primary" type="submit">
                {intl.formatMessage(common.send)}
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </Container>
    </form>
  );
}
