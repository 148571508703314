import React from "react";
import { useIntl } from "react-intl";

import { DatePicker } from "../../DatePicker";

import { CAMPAIGNS_LABELS, PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function StartDate({
  values,
  handleChange,
  productType,
  campaigns,
  campaign,
}) {
  const intl = useIntl();

  const getMinDate = () => {
    if (campaign) return false;
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id
      ? campaigns.find((c) => c.campaign_id === values.main_event_id).start_date
      : new Date();
  };

  const getMaxDate = () => {
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id
      ? campaigns.find((c) => c.campaign_id === values.main_event_id)
          .expiration_date
      : new Date("2100-01-01");
  };
  return (
    <DatePicker
      label={intl.formatMessage(
        productType === CAMPAIGNS_LABELS.PASS
          ? form.startDatePass
          : form.startDate
      )}
      value={values.start_date}
      onChange={handleChange("start_date")}
      key="start_date"
      fullWidth
      autoOk
      required
      minDate={getMinDate()}
      maxDate={getMaxDate()}
      disabled={!!campaign}
    />
  );
}
