import React from "react";
import { useIntl } from "react-intl";

import { MenuItem, TextField } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function MainEventId({ values, handleChange, campaigns = [] }) {
  const intl = useIntl();

  const formatDate = (date) => (date ? intl.formatDate(new Date(date)) : "");

  const mainEvents = (campaign) =>
    campaign.type === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
    campaign.campaign_data.rules.main_event_id === "" &&
    new Date(campaign.expiration_date) > new Date();

  return (
    values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <TextField
        name="main_event_id"
        label={intl.formatMessage(form.mainEvent)}
        value={values.main_event_id}
        onChange={handleChange("main_event_id")}
        key="main_event_id"
        select
        fullWidth
        margin="normal"
      >
        <MenuItem value="">{intl.formatMessage(form.none)}</MenuItem>
        {campaigns?.filter(mainEvents).map((campaign) => (
          <MenuItem key={campaign.campaign_id} value={campaign.campaign_id}>
            {campaign.name} ({formatDate(campaign.campaign_data.start_date)} →{" "}
            {formatDate(campaign.campaign_data.expiration_date)})
          </MenuItem>
        ))}
      </TextField>
    )
  );
}
