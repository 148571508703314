import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { PRODUCT_TYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function ProductName({ values, handleChange }) {
  const intl = useIntl();

  return (
    values.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS && (
      <TextField
        name="products_name"
        label={intl.formatMessage(form.productsName)}
        value={values.products_name}
        onChange={handleChange("products_name")}
        key="products_name"
        fullWidth
        margin="normal"
        required
      />
    )
  );
}
